import Vue from "vue";
import { Module } from "vuex";

import { Business, Client, Order } from "@/types";
import { api } from "@/util/axios";

type cartState = {
  cart: {
    products: CartProduct[];
    services: CartService[];
    memberships: CartMembership[];
    vouchers: CartVoucher[];
    servicePackages: CartServicePackage[];
  };
  client?: Client;
  roomAvailability: any[];
  payOnlyDeposit: boolean;
};

const cart: Module<cartState, unknown> = {
  namespaced: true,
  state: () => ({
    cart: {
      products: [],
      services: [],
      memberships: [],
      vouchers: [],
      servicePackages: [],
    },
    client: undefined,
    roomAvailability: [],
    payOnlyDeposit: false,
  }),
  getters: {
    products: (state) => state.cart.products,
    depositAfterDiscount: (state) =>
      state.cart.services
        .map((item) => item.depositAfterDiscount)
        .reduce((a, b) => a + b, 0),
    memberships: (state) => state.cart.memberships,
    vouchers: (state) => state.cart.vouchers,
    client: (state) => state.client,
    servicePackages: (state) => state.cart.servicePackages,
    getDiscount: (state) => {
      return [
        ...state.cart.products.map((item) => item.discount * item.quantity),
        ...state.cart.services.map((item) =>
          state.payOnlyDeposit
            ? (item.depositAmount - item.depositAfterDiscount) * item.quantity
            : item.discount * item.quantity
        ),
        ...state.cart.servicePackages.map(
          (item) => item.discount * item.quantity
        ),
        ...state.cart.memberships.map((item) => item.discount * item.quantity),
      ].reduce((a, b) => a + b, 0);
    },
    payableAmount: (state) => (shippingCost: number) => {
      if (state.payOnlyDeposit) {
        const grandTotal =
          [
            ...state.cart.products,
            ...state.cart.memberships,
            ...state.cart.vouchers,
            ...state.cart.servicePackages,
          ]
            .map((item) => item.quantity * item.unitPrice)
            .reduce((a, b) => a + b, 0) +
          state.cart.services
            .map((item) => item.depositAfterDiscount * item.quantity)
            .reduce((a, b) => a + b, 0);
        const discountAmount = [
          ...state.cart.products,
          ...state.cart.memberships,
          ...state.cart.servicePackages,
        ]
          .map((item) => item.quantity * item.discount)
          .reduce((a, b) => a + b, 0);

        console.log("A. grandTotal: ", {
          grandTotal,
          shippingCost,
          discountAmount,
        });

        return grandTotal + shippingCost - discountAmount;
      }

      const grandTotal = [
        ...state.cart.products,
        ...state.cart.memberships,
        ...state.cart.vouchers,
        ...state.cart.servicePackages,
        ...state.cart.services,
      ]
        .map((item) => item.quantity * item.unitPrice)
        .reduce((a, b) => a + b, 0);
      const discountAmount = [
        ...state.cart.products,
        ...state.cart.memberships,
        ...state.cart.services,
        ...state.cart.servicePackages,
      ]
        .map((item) => item.quantity * item.discount)
        .reduce((a, b) => a + b, 0);
      console.log("B. grandTotal: ", {
        grandTotal,
        shippingCost,
        discountAmount,
      });

      return grandTotal + shippingCost - discountAmount;
    },
    getService: (state) => (serviceId: string) =>
      state.cart.services.find((c) => c.id === serviceId),
    services: (state) => state.cart.services,
    total: (state) =>
      [
        ...state.cart.products,
        ...state.cart.memberships,
        ...state.cart.vouchers,
        ...state.cart.servicePackages,
        ...state.cart.services,
      ]
        .map((item) => item.quantity * item.unitPrice)
        .reduce((a, b) => a + b, 0),
    quantity: (state) =>
      [
        ...state.cart.products,
        ...state.cart.memberships,
        ...state.cart.vouchers,
        ...state.cart.servicePackages,
        ...state.cart.services,
      ]
        .map((item) => item.quantity)
        .reduce((a, b) => a + b, 0),
    roomAvailability: (state) => state.roomAvailability,
    getCurrentServiceRooom: (state) => (serviceId: string) => {
      if (state.roomAvailability && Object.keys(state.roomAvailability)) {
        const key = Object.keys(state.roomAvailability).find((x) =>
          x.includes(serviceId)
        );
        if (!key) {
          return null;
        } else {
          return state.roomAvailability[key].length > 0
            ? state.roomAvailability[key][0]._id
            : null;
        }
      }
      return null;
    },
    payOnlyDeposit: (state) => state.payOnlyDeposit,
  },
  mutations: {
    SET_CLIENT: (state, _client: Client) => {
      state.client = _client;
    },
    UPDATE_PRODUCT: (state, item) => {
      const idx = state.cart.products.findIndex((s) => s.sku === item.sku);

      if (idx > -1) {
        if (item.quantity === 0) {
          state.cart.products.splice(idx, 1);
        } else Vue.set(state.cart.products, idx, item);
      } else {
        state.cart.products.push(item);
      }
    },
    UPDATE_MEMBERSHIP: (state, item) => {
      const idx = state.cart.memberships.findIndex((s) => s.id === item.id);

      if (idx > -1) {
        if (item.quantity === 0) {
          state.cart.memberships.splice(idx, 1);
        } else Vue.set(state.cart.memberships, idx, item);
      } else {
        state.cart.memberships.push(item);
      }
    },
    UPDATE_VOUCHER: (state, item) => {
      const idx = state.cart.vouchers.findIndex((s) => s.id === item.id);

      if (idx > -1) {
        if (item.quantity === 0) {
          state.cart.vouchers.splice(idx, 1);
        } else Vue.set(state.cart.vouchers, idx, item);
      } else {
        state.cart.vouchers.push(item);
      }
    },
    UPDATE_PACKAGE: (state, item) => {
      const idx = state.cart.servicePackages.findIndex(
        (s) => s.servicePackage === item.servicePackage
      );

      if (idx > -1) {
        if (item.quantity === 0) {
          state.cart.servicePackages.splice(idx, 1);
        } else Vue.set(state.cart.servicePackages, idx, item);
      } else {
        state.cart.servicePackages.push(item);
      }
    },
    UPDATE_SERVICE: (state, item) => {
      const idx = state.cart.services.findIndex((s) => s.id === item.id);

      if (idx > -1) {
        if (item.quantity === 0) {
          state.cart.services.splice(idx, 1);
        } else Vue.set(state.cart.services, idx, item);
      } else {
        state.cart.services.push(item);
      }
    },
    SET_ROOM_AVAILABILITY: (state, availability) => {
      state.roomAvailability = availability;
    },
    DELETE_CART: (state) => {
      state.cart.products = [];
      state.cart.services = [];
      state.cart.memberships = [];
      state.cart.vouchers = [];
      state.cart.servicePackages = [];
      state.client = undefined;
    },
    SET_PAY_ONLY_DEPOSIT: (state, payOnlyDeposit) => {
      state.payOnlyDeposit = payOnlyDeposit;
    },
  },
  actions: {
    addCartClient(context, client: Client) {
      context.commit("SET_CLIENT", client);
    },
    deleteCart(context) {
      context.commit("DELETE_CART");
    },
    async getService(context, payload) {
      const {
        serviceId,
        employeeId,
        durationInMinutes,
        location: loc,
        room,
        appointmentTime,
        appointmentDate,
        unitPrice,
        quantity,
      } = payload;
      let params = `?discountPlatform=vendor-ui`;

      if (serviceId) params += `&serviceId=${serviceId}`;
      if (employeeId) params += `&employeeId=${employeeId}`;
      if (durationInMinutes)
        params += `&durationInMinutes=${durationInMinutes}`;

      if (loc) params += `&location=${loc}`;

      if (unitPrice) params += `&unitPrice=${unitPrice}`;
      return await api
        .get<{ service: ServicePricing }>(`/v1/cart/service-pricing${params}`)
        .then((response) => {
          const item = response.data.service;
          if (!item) return;

          let startDate;

          if (appointmentDate && appointmentTime) {
            let date = appointmentDate;

            date = (date as string).split("-");
            const [hr, min] = appointmentTime.split(":");

            startDate = new Date(
              +date[0],
              +date[1] - 1,
              +date[2],
              +hr,
              +min
            ).toISOString();
          }
          const service: CartService = {
            appointmentTime,
            appointmentDate,
            staff: employeeId,
            quantity,
            name: item.name,
            unitPrice: item.advancedPricing[0].price,
            subTotal: item.advancedPricing[0].price * quantity,
            id: item._id,
            discount: item.advancedPricing[0].computedDiscount,
            depositAfterDiscount: item.advancedPricing[0].depositAfterDiscount,
            depositAmount: item.advancedPricing[0].computedDepositAmount,
            discountedPrice: item.advancedPricing[0].discountedPrice ?? 0,
            startDate,
            priceId: item.advancedPricing[0]._id,
            durationInMinutes:
              durationInMinutes ?? item.advancedPricing[0].durationInMinutes,
            room: room ?? context.getters.getCurrentServiceRooom(item._id),
            location: loc,
            service: {
              name: item.name,
              images: item.images,
              _id: item._id,
            } as any,
          };

          // console.log("pyld: ", payload, service);

          context.commit("UPDATE_SERVICE", service);
        })
        .catch((error) => {
          console.log("e: ", error);
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async getProduct(context, payload) {
      const { productId, unitPrice, quantity } = payload;
      let params = `?discountPlatform=vendor-ui`;

      if (productId) params += `&productId=${productId}`;

      if (unitPrice) params += `&unitPrice=${unitPrice}`;
      return await api
        .get<{ product: ProductPricing }>(`/v1/cart/product-pricing${params}`)
        .then((response) => {
          const item = response.data.product;
          if (!item) return;

          const product: CartProduct = {
            product: item.product as any,
            quantity,
            title: item.title,
            unitPrice: item.pricing.retail_price,
            sku: item.sku,
            _id: item._id,
            subTotal: item.pricing.retail_price * quantity,
            discount: item.computedDiscount.retailPrice * quantity,
            discountedPrice: item.discountedPrice.retailPrice * quantity,
            // shipping: item.shipping,
          };
          context.commit("UPDATE_PRODUCT", product);
        })
        .catch((error) => {
          console.log("e: ", error);
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async getVoucher(context, payload) {
      const { voucherId, unitPrice, quantity, maxUsageCount } = payload;
      let params = `?voucherId=${voucherId}`;

      if (unitPrice) params += `&unitPrice=${unitPrice}`;
      return await api
        .get<{ voucher: VoucherPricing }>(`/v1/cart/voucher-pricing${params}`)
        .then((response) => {
          const item = response.data.voucher;
          if (!item) return;

          const voucher: CartVoucher = {
            id: item._id,
            quantity,
            name: item.title,
            unitPrice: item.retailPrice,
            subTotal: item.retailPrice * quantity,
            giftCode: item.code,
            giftValue: item.value,
            description: item.description,
            maxUsageCount: maxUsageCount,
          };
          context.commit("UPDATE_VOUCHER", voucher);
        })
        .catch((error) => {
          console.log("e: ", error);
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async getMembership(context, payload) {
      const { membershipId, unitPrice, quantity, type, period, duration } =
        payload;
      console.log("payload: ", payload);
      let params = `?discountPlatform=vendor-ui`;

      if (membershipId) params += `&membershipId=${membershipId}`;

      if (unitPrice) params += `&unitPrice=${unitPrice}`;
      return await api
        .get<{ membership: MembershipPricing }>(
          `/v1/cart/membership-pricing${params}`
        )
        .then((response) => {
          const item = response.data.membership;
          if (!item) return;

          const membership: CartMembership = {
            id: item._id,
            quantity,
            name: item.name,
            period,
            startDate: new Date(),
            membership: item as any,
            duration,
            type,
            unitPrice: item.price[type],
            subTotal: item.price[type] * quantity,
            discount: item.computedDiscount[type] * quantity,
            discountedPrice: item.discountedPrice[type] * quantity,
          };
          context.commit("UPDATE_MEMBERSHIP", membership);
        })
        .catch((error) => {
          console.log("e: ", error);
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async getServicePackage(context, payload) {
      const { servicePackageId, unitPrice, quantity } = payload;
      let params = `?discountPlatform=vendor-ui`;

      if (servicePackageId) params += `&servicePackageId=${servicePackageId}`;

      if (unitPrice) params += `&unitPrice=${unitPrice}`;
      return await api
        .get<{ servicePackage: ServicePackagePricing }>(
          `/v1/cart/service-package-pricing${params}`
        )
        .then((response) => {
          const item = response.data.servicePackage;
          if (!item) return;

          const servicePackage: CartServicePackage = {
            servicePackage: item._id,
            quantity,
            name: item.name,
            unitPrice: item.price,
            subTotal: item.price * quantity,
            discount: item.computedDiscount * quantity,
            discountedPrice: item.discountedPrice * quantity,
          };
          context.commit("UPDATE_PACKAGE", servicePackage);
        })
        .catch((error) => {
          console.log("e: ", error);
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    removeCartItem(context, payload) {
      const { itemType, item } = payload;
      switch (itemType) {
        case "product":
          context.commit("UPDATE_PRODUCT", { sku: item.sku, quantity: 0 });
          break;
        case "service":
          context.commit("UPDATE_SERVICE", { id: item.id, quantity: 0 });
          break;
        case "membership":
          context.commit("UPDATE_MEMBERSHIP", { id: item.id, quantity: 0 });
          break;
        case "voucher":
          context.commit("UPDATE_VOUCHER", { id: item.id, quantity: 0 });
          break;
        case "service-package":
          context.commit("UPDATE_PACKAGE", {
            servicePackage: item.servicePackage,
            quantity: 0,
          });
          break;
      }
    },
    async checkout(context, { payment, shipping, storePickup, notes }) {
      const client = context.state.client as Client;
      const data: CheckoutInput = {
        businessId: (client.business as Business)._id,
        clientId: client._id,
        notes,
        discountPlatform: "vendor-ui",
        agent: "vendor",
        user: (context.rootState as any).auth.user._id,
        payment,
        shipping,
        storePickup,
        products: context.state.cart.products.map((item) => ({
          productId: item.product._id,
          quantity: item.quantity,
          sku: item._id,
          unitPrice: item.unitPrice,
        })),
        services: context.state.cart.services.map((item) => {
          if (!item.staff) {
            context.dispatch(
              "setToast",
              {
                title: "Request failed!",
                type: "error",
                text: "Please select a staff for each service.",
              },
              { root: true }
            );
            throw new Error("Please select a staff for each service.");
          }
          return {
            serviceId: item.id,
            employeeId: item.staff,
            quantity: item.quantity,
            unitPrice: item.unitPrice,
            startDate: item.startDate,
            durationInMinutes: item.durationInMinutes,
            priceId: item.priceId,
            room: item.room,
            location: item.location,
          };
        }),
        memberships: context.state.cart.memberships.map((m) => ({
          membershipId: m.id,
          duration: m.duration,
          priceFrequency: m.type,
          quantity: m.quantity,
          unitPrice: m.unitPrice,
        })),
        vouchers: context.state.cart.vouchers.map((v) => ({
          voucherId: v.id,
          quantity: v.quantity,
          maxUsageCount: v.maxUsageCount,
          unitPrice: v.unitPrice,
        })),
        servicePackages: context.state.cart.servicePackages.map((sp) => ({
          servicePackageId: sp.servicePackage,
          quantity: sp.quantity,
          unitPrice: sp.unitPrice,
        })),
      };
      return await api
        .post<{ order: Order; checkoutUrl: string }>("/v1/cart/checkout", data)
        .then((response) => {
          if (response.data.order) {
            context.dispatch(
              "setToast",
              {
                title: "Checkout successful!",
                type: "success",
                text: "Your order has been placed successfully.",
              },
              { root: true }
            );

            return response.data;
          }
        })
        .catch((error) => {
          console.log("e: ", error);
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchRoomAvailability(context, serviceRequests = []) {
      try {
        // Convert single request to array format if needed
        const requestPayload = Array.isArray(serviceRequests)
          ? serviceRequests
          : [
              {
                serviceId: serviceRequests.serviceId,
                businessId: serviceRequests.businessId,
                startDateIso: serviceRequests.startDate, // Note: Converting from startDate to startDateIso
                duration: Number(serviceRequests.duration),
              },
            ];

        // Always use POST request with array in body
        const response = await api.post(
          `/v1/serviceRoom/availability`,
          requestPayload
        );

        // Handle response data based on whether it was a single request or multiple
        let availabilityData = response.data.availability;

        // For single request compatibility, extract the first result if it was converted from a single object
        if (
          !Array.isArray(serviceRequests) &&
          Object.keys(availabilityData).length === 1
        ) {
          availabilityData = availabilityData[Object.keys(availabilityData)[0]];
        }

        context.commit("SET_ROOM_AVAILABILITY", availabilityData);
        return availabilityData;
      } catch (error) {
        context.dispatch(
          "setToast",
          {
            title: "Request failed!",
            type: "error",
            text:
              (error as any).response?.data?.error?.message ||
              "Failed to fetch room availability",
          },
          { root: true }
        );

        // Re-throw the error so the caller can handle it if needed
        throw error;
      }
    },
    setPayOnlyDeposit(context, payOnlyDeposit) {
      context.commit("SET_PAY_ONLY_DEPOSIT", payOnlyDeposit);
    },
  },
};

export default cart;
